import Logo from "./valobit.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Metamask from "./assets/images/Metamask.png";
import Connectwallet from "./assets/images/trustwallet.png";
import {
  AiOutlineAlignRight,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import backendConfig from "./config/config";
import Web3 from "web3";
import { useEffect } from "react";
import sunflowerABI from "./ABI/vbitmlm.json";
import { useNavigate, Link } from "react-router-dom";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon, bsc, sepolia } from "wagmi/chains";
import ETH from "./switchnetwork";
import Matic from "../src/matic";
import $ from "jquery";
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import Bannerimage1 from "./assets/images/banner-image-1.png";
import Bannerimage2 from "./assets/images/banner-image-2.png";



function App() {

  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState("");
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const { open, close } = useWeb3Modal();
  const [userexiststatus, setuserexiststatus] = useState(false);


  const handleClose = () => {
    setShow(false);
  };

  const walletopen = async () => {
    let data = await open();
    setInterval(() => {
      if (localStorage.getItem("wagmi.connected")) {
        close();
        window.location.reload(true);
      }
    }, 2000);
  };

  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }


  const metamaskconnect = async () => {
    if (window.ethereum) {
      let backchain = backendConfig.chainId;
      let metchainid = window.ethereum.chainId;
      if (backchain == metchainid) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let address = localStorage.getItem("address");
        walletdetails();
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      } else {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let address = localStorage.getItem("address");
        walletdetails();
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      }
    } else {
      toast.warn("Please install metamask");
      openInNewTab(
        `https://metamask.io/download/`
      );
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const connectAddress = async () => {
    const userDataString = localStorage.getItem("wagmi.store");
    const userData = JSON.parse(userDataString);
    let address = userData.state.data.account;
    if (address) {
      window.location.reload();
      trustWallet(address);
      setUserAddress(
        address.substring(0, 5) + "..." + address.substring(address.length - 4)
      );
      $("#joinnowbuttonhide").hide();
    }
  };

  const trustWallet = async (address) => {
    let web3js = new Web3(backendConfig.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      backendConfig.BKC_MLMContract
    );
    let userexists = await sunflowerContract.methods
      .isUserExists(address)
      .call();
    if (userexists == true) {
      setuserexiststatus(true);
    } else {
      setuserexiststatus(false);
    }
  };

  const chains = [mainnet, polygon, ETH, bsc, sepolia, Matic];
  const projectId = "1f0b9efb280db82bff5edc4689ffa76a";

  const trustWalletConnect = async () => {
    const provider = await EthereumProvider.init({
      projectId: "1f0b9efb280db82bff5edc4689ffa76a",
      chains: [1, 56, 137, 2535]
    })
    provider.on('disconnect', (code, reason) => {
      window.location.reload();
      localStorage.clear()
    });
  }

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  const disconnect = () => {
    localStorage.clear();
    window.location.reload();
  };

  const walletdetails = async () => {
    const ethereumAddress = wagmiConfig.ethereumAddress;
    let result = localStorage.getItem("address");
    if (result) {
      let address = result;
      let web3js = new Web3(backendConfig.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(
        sunflowerABI,
        backendConfig.BKC_MLMContract
      );
      let userexists = await sunflowerContract.methods
        .isUserExists(address)
        .call();
      if (userexists == true) {
        setuserexiststatus(true);
        $("#buypackagehide").show();
      } else {
        setuserexiststatus(false);
      }
    }
  };

  useEffect(() => {
    $("#buypackagehide").hide();
    let metaaddress = localStorage.getItem("address");

    if(metaaddress != null ) {
      if(metaaddress != undefined) {
        setUserAddress(
          metaaddress.substring(0, 5) +
            "..." +
            metaaddress.substring(metaaddress.length - 4)
        );
        $("#joinnowbuttonhide").show();
        userdashboardshow(metaaddress);
      }
      else {
        $("#joinnowbuttonhide").hide();
      }
    }else{
      let userMetaAddress = localStorage.getItem("wagmi.store");
      if(userMetaAddress){
        let userMeta = JSON.parse(userMetaAddress);
        if(userMeta){
          let addr = userMeta.state.data.account;
          if(addr){
               setUserAddress(
                  addr.substring(0, 5) + "..." + addr.substring(addr.length - 4)
                );
                $("#joinnowbuttonhide").show();
                userdashboardshow(addr);
          }
          else {
            $("#joinnowbuttonhide").hide();
          }
        }
        else {
          $("#joinnowbuttonhide").hide();
        }
      }
      else {
        $("#joinnowbuttonhide").hide();
      }
    }
    trustWalletConnect()
  }, []);

  
  const userdashboardshow = async (userMetaAddress) => {

    let web3js = new Web3(backendConfig.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      backendConfig.BKC_MLMContract
    );
    let userexists = await sunflowerContract.methods.isUserExists(userMetaAddress).call();
    if (userexists == true) {
      setuserexiststatus(true);
      $("#buypackagehide").show();
    } else {
      setuserexiststatus(false);
    }
  };

  


  const joinnowbutton = async () => {

    if (localStorage.getItem('address')) {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        
        localStorage.setItem("address", accounts[0]);
        let web3js = new Web3(backendConfig.rpcUrls);
       
        let sunflowerContract = new web3js.eth.Contract(sunflowerABI,backendConfig.BKC_MLMContract);
        
        let userexists = await sunflowerContract.methods.isUserExists(accounts[0]).call();
        if (userexists) {
          navigate("/network");
        } else {
          navigate("/network");
        }
      }
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;

      if (addr != null) {
        localStorage.setItem("address", addr);
        let web3js = new Web3(backendConfig.rpcUrls);
        let sunflowerContract = new web3js.eth.Contract(
          sunflowerABI,
          backendConfig.BKC_MLMContract
        );
        let userexists = await sunflowerContract.methods
          .isUserExists(addr)
          .call();
        if (userexists) {
          navigate("/network");
        } else {
          navigate("/network");
        }
      }
    }
  }


  const buypackage = async() => {
    if(window.ethereum) {
      navigate("/network");
    }
  }


  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white">
              <AiOutlineAlignRight />
            </span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 gap-3">
              
            </ul>
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
              <li class="nav-item" id="buypackagehide">
                  <a
                    class="nav-link main-nav-connect-button"
                    onClick={buypackage}
                  >
                    BUY package
                  </a>
              </li>
              <li class="nav-item" id="joinnowbuttonhide">
                <a
                    class="nav-link main-nav-connect-button"
                    onClick={joinnowbutton}
                  >
                    Join Now
                  </a>
              </li>
              {userAddress ? (
                <a class="nav-link main-nav-connect-button">{userAddress}</a>
              ) : (
                <a class="nav-link main-nav-connect-button" onClick={handleShow}>
                  Connect Wallet
                </a>
              )}

              <div className="modal-section">
                <WagmiConfig config={wagmiConfig}>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="d-flex justify-content-center"
                  >
                    <Modal.Header closeButton></Modal.Header>

                    <Modal.Body>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleClose();
                          metamaskconnect();
                        }}
                        className="connect-metamask-button"
                      >
                        <img src={Metamask} style={{ width: "30px" }} alt="" />
                        Connect Metamask
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => {
                          walletopen();
                          handleClose();
                          connectAddress();
                        }}
                        className="connect-metamask-button ms-3"
                      >
                        <img
                          src={Connectwallet}
                          style={{ width: "30px" }}
                          className="me-2"
                          alt=""
                        />
                           Trust Wallet
                      </Button>
                    </Modal.Body>
                  </Modal>
                </WagmiConfig>
                <Web3Modal
                  enableAccountView={true}
                  projectId={projectId}
                  ethereumClient={ethereumClient}
                />
              </div>

              <li>
                {userAddress ? (
                  <button class="main-nav-connect-button" onClick={disconnect}>
                    Disconnect
                  </button>
                ) : (
                  <a></a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="banner-new-top-new-1">
        <div className="container-fluid">
          <div className="row min-vh-100 align-content-center text-center" >
            <div className="col-6">
              <img className="banner-new-top-images-1" src={Bannerimage1} alt="Bannerimage1" />
            </div>
            <div className="col-6">
              <img className="banner-new-top-images-1" src={Bannerimage2} alt="Bannerimage2" />
            </div>
            <div>

            
              <button class="main-nav-connect-button bg-primary" style={{width:"fit-content"}}>
                  <a 
                    href="/" 
                  >
                    polyvbit staking
                  </a>
              </button>
        
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default App;