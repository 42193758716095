import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Home from "./Home";
import Dashboard from "./Dashboard";
import Sbtdashboard  from "./Sbtdashboard";
import ProtectedRoute from "./auth/ProductedRoute";
import Network from "./Network";

import Register from "./Registration";
import Sbtregister from "./Sbtregistration";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";


function App() {

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/register" element={<ProtectedRoute> {" "} <Register />{" "}</ProtectedRoute>}/>
          <Route path="/register/:network/:id" element={<ProtectedRoute>{" "}<Register />{" "}</ProtectedRoute>}
          />
          <Route path="/register/:network" element={
              <ProtectedRoute>
                {" "}
                <Register />{" "}
              </ProtectedRoute>
            }
          />
          <Route path="/sbtregister/:id" element={<Sbtregister />}/>
          <Route path="/sbtregister" element={
              <ProtectedRoute>
                {" "}
                <Sbtregister />{" "}
              </ProtectedRoute>
            }
          />
          <Route path="/dashboard" element={
              <ProtectedRoute>
                {" "}
                <Dashboard />{" "}
              </ProtectedRoute>}
          />
          <Route path="/sbtdashboard" element={
              <ProtectedRoute>
                {" "}
                <Sbtdashboard />{" "}
              </ProtectedRoute>}
          />
          <Route path="/network" element={<ProtectedRoute>{" "}<Network />{" "}</ProtectedRoute>}/>
          <Route path="/network/:id" element={<Network />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
