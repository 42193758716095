// import config from "./config/tokenaddressconfig"

const MATIC = {
    id: 137,
    name: 'MATIC',
    network: 'MATIC',
    nativeCurrency: {
      decimals: 18,
      name: 'MATIC',
      symbol: 'MATIC',
    },
    rpcUrls: {
      public: { http: ['https://polygon-rpc.com/'] },
      default: { http: ['https://polygon-rpc.com/'] },
    },
    blockExplorers: {
      etherscan: { name: 'MATIC Smart Chain', url: 'https://polygonscan.com' },
      default: { name: 'MATIC Smart Chain', url: 'https://polygonscan.com' },
    },
    
}  

export default MATIC
  
  