import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {

    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('address');

        const userMetaAddress = localStorage.getItem("wagmi.store");
        const userMeta = JSON.parse(userMetaAddress);
        const addr = userMeta.state.data.account;

        if (userToken === null) {
            if (addr == null) {
                setIsLoggedIn(false);
                return navigate('/');
            }
            else {
                console.log('addr null');
            }
        }
    }


    useEffect(() => {
        checkUserToken();
    },);

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;
