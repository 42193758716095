import Logo from "./valobit.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Metamask from "./assets/images/Metamask.png";
import Connectwallet from "./assets/images/trustwallet.png";
import {
  AiOutlinePoweroff,
} from "react-icons/ai";
import { AiOutlineAlignRight } from "react-icons/ai";
import $ from "jquery";
import { useState, useEffect } from "react";
import Web3 from "web3";
import tokenABI from "./ABI/busd.json";
import sbtmlmstakingABI from "./ABI/sbtmlmstaking.json";
import config from "./config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon, bsc, sepolia } from "wagmi/chains";
import ETH from "./switchnetwork";
import Matic from "../src/matic";
import backendConfig from "./config/config";

var jq = $.noConflict();



function Sbtregister() {

  const navigate = useNavigate();

  const [isEnabled, setIsEnabled] = useState(true);
  const [userAddress, setUserAddress] = useState("");
  const [message, setMessage] = useState("");
  const [parentAddress, setparentAddress] = useState("0x00");
  const [useridnotexist, setuseridnotexist] = useState("");
  const [loader, setLoader] = useState(false);
  const [parentIdValue, setParentIdValue] = useState("");
  const [Sbtdatatype, setSbtdatatype] = useState([]);
  const [userenterPolyAmount, setuserenterPolyAmount] = useState("");
  const [userdistributePolyamount, setuserdistributePolyamount] =
    useState("");
  const [userpolypackage, setuserpolypackage] = useState("");
  const [planamount, setplanamount] = useState("0 SBT");
  const [plandurations,setplandurations] = useState("60");
  const [userexiststatus,setuserexiststatus] = useState(false)
  const [useridvalue,setuseridvalue] = useState("");
  const [usermetamaskaddress,setusermetamaskaddress] = useState("")

  const [usrpackagemonths,setusrpackagemonths] = useState("")


  const chains = [mainnet, polygon, ETH, bsc, sepolia, Matic];
  const projectId = "1f0b9efb280db82bff5edc4689ffa76a";


  const handleClose = () => {
    setShow(false);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const connectAddress = async () => {
    const userDataString = localStorage.getItem("wagmi.store");
    const userData = JSON.parse(userDataString);
    let address = userData.state.data.account;
    if (address) {
      window.location.reload();
      setUserAddress(
        address.substring(0, 5) + "..." + address.substring(address.length - 4)
      );
      $("#joinnowbuttonhide").hide();
    }
  };

  const walletopen = async () => {
    let data = await open();
    setInterval(() => {
      if (localStorage.getItem("wagmi.connected")) {
        close();
        window.location.reload(true);
      }
    }, 2000);
  };

  const metamaskconnect = async () => {
    if (window.ethereum) {
      let backchain = backendConfig.chainId;
      let metchainid = window.ethereum.chainId;
      if (backchain == metchainid) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let address = localStorage.getItem("address");
        getusrplan(address)
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      } else {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let address = localStorage.getItem("address");
        getusrplan(address)
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      }
    } else {
      toast.warn("Please install metamask");
      openInNewTab(
        `https://metamask.io/download/`
      );
    }
  };

  const trustWalletConnect = async () => {
    const provider = await EthereumProvider.init({
      projectId: "1f0b9efb280db82bff5edc4689ffa76a",
      chains: [1, 56, 137, 2535]
    })
    provider.on('disconnect', (code, reason) => {
      window.location.reload();
      localStorage.clear()
    });
  }

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);


  const parentaddresssetval = async (event) => {
    setMessage(event);
    try {
      let web3 = new Web3(config.rpcUrls);
      let sunflowerContract = new web3.eth.Contract(
        sbtmlmstakingABI,
        config.SBT_STAKING_ADDRESS
      );
      let findUserID = await sunflowerContract.methods.lastUserId().call();

      let activeUsers = parseInt(findUserID) - 1;

      if (activeUsers >= event) {
        setuseridnotexist("");
        let idBasedAddress = await sunflowerContract.methods
          .idToAddress(event)
          .call();
        setparentAddress(idBasedAddress);
      } else {
        setparentAddress();
        setuseridnotexist("User Id doesn't exist");
      }
    } catch (error) {
      console.log("findParentId error", error);
    }
  };


  const userdashboardshow = async (userMetaAddress) => {
      
    let web3js = new Web3(window.ethereum);
    let sunflowerContract = new web3js.eth.Contract(
      sbtmlmstakingABI,
      config.SBT_STAKING_ADDRESS
    );
    let userexists = await sunflowerContract.methods.isUserExists(userMetaAddress).call();
    if (userexists == true) {
      let idBasedAddress = await sunflowerContract.methods.users(userMetaAddress)
          .call();
      setparentAddress(idBasedAddress.referrer)
      let refeAddress = await sunflowerContract.methods.users(idBasedAddress.referrer)
          .call();
        setMessage(parseInt(refeAddress.id))
        setuseridvalue(parseInt(idBasedAddress.id).toString())
      setuserexiststatus(true);
    } else {
      let findUserID = await sunflowerContract.methods.lastUserId().call();
        console.log("findUserID--",findUserID);
      setuseridvalue(parseInt(findUserID).toString())
      setuserexiststatus(false);
    }
  };


  const handleChange = async (event) => {
    const inputValue = event.target.value;
    if (/^[1-9]\d*$/.test(inputValue)) {
      setMessage(inputValue);
      try {
        let web3 = new Web3(config.rpcUrls);
        let sunflowerContract = new web3.eth.Contract(
          sbtmlmstakingABI,
          config.SBT_STAKING_ADDRESS
        );
        let findUserID = await sunflowerContract.methods.lastUserId().call();
  
        let activeUsers = parseInt(findUserID) - 1;
        const inputValueAsInt = parseInt(inputValue);
        if (activeUsers >= inputValueAsInt) {
          let idBasedAddress = await sunflowerContract.methods
            .idToAddress(inputValueAsInt)
            .call();
          setparentAddress(idBasedAddress);
          setuseridnotexist(""); // Reset the error message
        } else {
          setparentAddress();
          setuseridnotexist("User Id doesn't exist");
        }
      } catch (error) {
        console.log("findParentId error", error);
      }
    }  else {
      setMessage("");
      setparentAddress();
      setuseridnotexist("User Id doesn't exist");
    }
  };
  
    const findParentId = async (message) => {
      try {
        let web3 = new Web3(config.rpcUrls);
        let sunflowerContract = new web3.eth.Contract(
            sbtmlmstakingABI,
            config.SBT_STAKING_ADDRESS
        );
        let findUserID = await sunflowerContract.methods.lastUserId().call();
        let activeUsers = parseInt(findUserID) - 1;
        if (activeUsers >= message) {
            let idBasedAddress = await sunflowerContract.methods.idToAddress(message).call();
            setparentAddress(idBasedAddress);
        } else {
            setparentAddress();
            toast.error("User doesn't match..!");
        }
      } catch (error) {
        console.log("findParentId error", error);
      }
    };

    const getallplans = async () => {
        const getallsbt = await axios.get(
        `${config.BACKEND_URL}get-all-plans?network=SBT`
        );
        setSbtdatatype(getallsbt.data.data)
    };

    const getsbtpln = async() => {
        const getallbkc = await axios.get(`${config.BACKEND_URL}get-all-plans?network=SBT`);
        setSbtdatatype(getallbkc.data.data);
        let sbtplns = getallbkc.data.data;
        return sbtplns;
    }
 
    const getusrplan = async(useraddress) => {
        const getusraddr = await axios.get(
          `${config.BACKEND_URL}sbtuserpackage?address=${useraddress}`
        );
        let datlen = getusraddr.data.data;
        if(datlen.length > 0) {
            let arr = []
            for(let i=0; i < datlen.length; i++) {
                const startDate = new Date(datlen[i].dateTime);
                const currentDate = new Date();
                const timeDifference = currentDate - startDate;
                const millisecondsPerDay = 1000 * 60 * 60 * 24;
                const daysPassed = Math.floor(timeDifference / millisecondsPerDay);
                const endDay = 60;
                const remainingDays = endDay - daysPassed;
                if(remainingDays > 0) {
                  arr.push(datlen[i].package)
                }
            } 
            let datasplan = await getsbtpln()
            let pack = datasplan.filter(item => !arr.includes(item.durations));
            setSbtdatatype(pack);
        }
        else {
          getallplans()
        }
    }

    const swicthnetwork = async() => {
      var web3js = new Web3(config.rpcUrls);
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex(config.chainId) }]
      });
    }

  useEffect(() => {

    let userMetaAddress = localStorage.getItem("address");
    if(userMetaAddress){
      swicthnetwork()
      userdashboardshow(userMetaAddress)
      const parentAdd = window.location.href.split("/")[4]; 
      let addrlower = userMetaAddress.toLowerCase()
      setusermetamaskaddress(addrlower)
      getusrplan(addrlower)

      if (parentAdd !== undefined) {
        if(parentAdd > 0) {
          localStorage.setItem("parentId", parentAdd);
          let prms = localStorage.getItem("parentId");
          if (prms !== null) {
            setIsEnabled(!isEnabled);
            parentaddresssetval(prms);
          }
          setParentIdValue(parentAdd);
          findParentId(parentAdd);
          setMessage(parentAdd);
        }
      }

      if (userMetaAddress != null) {
        userdashboardshow(userMetaAddress)
        setUserAddress(
          userMetaAddress.substring(0, 5) +
            "..." +
            userMetaAddress.substring(userMetaAddress.length - 4)
        );
      }
    }
    else {
      let userMetaAddress = localStorage.getItem("wagmi.store");
      let userMeta = JSON.parse(userMetaAddress);
      let addr = userMeta.state.data.account;
      if(addr){
        setusermetamaskaddress(addr)
        userdashboardshow(addr)
        getusrplan(addr)
        setUserAddress(
          addr.substring(0, 5) + "..." + addr.substring(addr.length - 4)
        );
      }
      const parentAdd = window.location.href.split("/")[4];
      if (parentAdd !== undefined) {
        if(parentAdd > 0) { 
          localStorage.setItem("parentId", parentAdd);
          let prms = localStorage.getItem("parentId");
          if (prms !== null) {
            setIsEnabled(!isEnabled);
            parentaddresssetval(prms);
          }
          setParentIdValue(parentAdd);
          findParentId(parentAdd);
          setMessage(parentAdd);
        }
      }
    }
  }, []);

  
  const valobitlogout = async () => {
      localStorage.clear();
      navigate("/");
    };


    const dashboardpage = async() => {
      let web3 = new Web3(config.rpcUrls);
      let sbtmlmContract = new web3.eth.Contract(
          sbtmlmstakingABI,
          config.SBT_STAKING_ADDRESS
      );
      let userexists = await sbtmlmContract.methods.isUserExists(usermetamaskaddress).call();
      if(userexists) {
        navigate('/sbtdashboard')
      }
      else {
        toast.warn("Buy package");
      }
    }



  const register = async () => {
    if(localStorage.getItem("address")) {
    if(userenterPolyAmount == "") {
      toast.error("You must select package");
    }
    else if (message == "" || parentAddress == undefined) {
      toast.error("Parent Id must");
    } else {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      swicthnetwork()
      let userAdd = accounts[0];
      let parentIdValue = message;
        if(userenterPolyAmount > 0) {
          try {
            setLoader(true);
            const web3 = new Web3(window.ethereum);
            let durationsdays = parseInt(plandurations) * 24 * 60 * 60;
            let amount = await web3.utils.toWei(userenterPolyAmount, "ether");
            let rewards = usrpackagemonths.toFixed(18);
            let rewardpersecond = await web3.utils.toWei(rewards, "ether");
            const mlmContract = new web3.eth.Contract(
                sbtmlmstakingABI,
                config.SBT_STAKING_ADDRESS);
            const sbttokenContract = new web3.eth.Contract(
                tokenABI,
                config.SBT_TOKEN_ADDRESS);
            let refAddress;
            if (parentIdValue != "") {
              refAddress = await mlmContract.methods.idToAddress(parentIdValue).call();
            } else {
              refAddress = await mlmContract.methods.idToAddress(1).call();
            }
            userdashboardshow(userAdd)
            if (userexiststatus == true) {
              await sbttokenContract.methods.approve(config.SBT_STAKING_ADDRESS,amount).send({
                from:userAdd
              })
              let addplan = await mlmContract.methods.AddPlan(amount, durationsdays, rewardpersecond)
                .send({ 
                  from: userAdd
                 });
                let payload = {
                  userID : useridvalue,
                  user_address: userAdd,
                  amount: userenterPolyAmount,
                  distributeamount: userdistributePolyamount,
                  referenceID: `${parentIdValue}`,
                  network: "SBT",
                  package: userpolypackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/sbtdashboard");
            } else {
              let useridvalues = await mlmContract.methods.lastUserId().call();
                await sbttokenContract.methods.approve(config.SBT_STAKING_ADDRESS,amount).send({
                    from:userAdd
                })
                let transfer = await mlmContract.methods.register(refAddress, amount, durationsdays, rewardpersecond)
                .send({from: userAdd});

                let payload = {
                  userID : parseInt(useridvalues),
                  user_address: userAdd,
                  amount: userenterPolyAmount,
                  distributeamount: userdistributePolyamount,
                  referenceID: `${parentIdValue}`,
                  network: "SBT",
                  package: userpolypackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/sbtdashboard");
            }
          } catch (error) {
            setLoader(false);
          }
        }
    }
  }
  else{
    if (message == "" || parentAddress == undefined) {
      toast.error("Referral Id must");
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;
      
      const chain = userMeta.state.data.chain.id;
      const address = addr
      let parentIdValue = message;

        if(userenterPolyAmount > 0) {  
          try {
            setLoader(true);
              const provider = await EthereumProvider.init({
                projectId :"1f0b9efb280db82bff5edc4689ffa76a",
                chains:[config.Bsc_chainId]
              })
              const web3 = new Web3(provider);
  
            let durationsdays = parseInt(plandurations) * 24 * 60 * 60;
            let amount = await web3.utils.toWei(userenterPolyAmount, "ether");
            let rewards = usrpackagemonths;
            let rewardpersecond = await web3.utils.toWei(rewards, "ether");
            const mlmContract = new web3.eth.Contract(
               sbtmlmstakingABI,
              config.SBT_STAKING_ADDRESS
            );
            const sbttokenContract = new web3.eth.Contract(
                tokenABI,
                config.SBT_TOKEN_ADDRESS);
            let refAddress;
            if (parentIdValue != "") {
              refAddress = await mlmContract.methods
                .idToAddress(parentIdValue)
                .call();
            } else {
              refAddress = await mlmContract.methods.idToAddress(1).call();
            }
            userdashboardshow(address)
            if (userexiststatus == true) {
                await sbttokenContract.methods.approve(config.SBT_STAKING_ADDRESS,amount).send({
                    from:address
                })
              let addplan = await mlmContract.methods.AddPlan(amount, durationsdays,rewardpersecond)
                .send({ from: address});
                let addres = address.toLowerCase()
                let payload = {
                  user_address: addres,
                  amount: userenterPolyAmount,
                  distributeamount: userdistributePolyamount,
                  referenceID: `${parentIdValue}`,
                  network: "SBT",
                  userID : useridvalue,
                  package: userpolypackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/sbtdashboard");
            } else {
                await sbttokenContract.methods.approve(config.SBT_STAKING_ADDRESS,amount).send({
                    from:address
                })
                let transfer = await mlmContract.methods.register(refAddress, amount, durationsdays, rewardpersecond)
                .send({ 
                  from: address
                });
                let addres = address.toLowerCase()
                let payload = {
                  user_address: addres,
                  amount: userenterPolyAmount,
                  distributeamount: userdistributePolyamount,
                  referenceID: `${parentIdValue}`,
                  network: "SBT",
                  userID : useridvalue,
                  package: userpolypackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/sbtdashboard");
            }
            
          } catch (error) {
            setLoader(false);
          }
        }
        else {
          toast.error("Select package");
        }
    }
  }
};


  const convertstrtomonths = async(joinamt,value) => {
    var match = value.match(/\d+/g);
    var letr =  value.match(/[a-zA-Z]+/g);
    
    if(letr[0][0] == "d" || letr[0][0] == "D") {
      let userentr = joinamt / (match[0] * 86400);
      return userentr;
    }
    else if(letr[0][0] == "m" && letr[0][1] == "o") {
      let userentr = joinamt / (match[0] * 2630016);
      return userentr;
    }
    else if(letr[0][0] == "m" && letr[0][1] == "i"){
      let userentr = joinamt / (match[0] * 60);
      return userentr;
    }
    else {
      let userentr = joinamt / (match[0] * 2630016);
      return userentr;
    }
  }

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);


  const { open, close } = useWeb3Modal();


  const setpackagePolyAmount = async (joinamount, distamount, polypackage,durations) => {
    let num = await convertstrtomonths(joinamount,durations)
    $(".dash-new-card").on("click", function () {
      $(".dash-new-card").removeClass("dashboard-active");
      $(this).addClass("dashboard-active");
    });
    setplandurations(joinamount);
    setplanamount(joinamount + " SBT"); 
    setuserenterPolyAmount(joinamount);
    setuserdistributePolyamount(distamount);
    setuserpolypackage(durations);
    setusrpackagemonths(num)
  };

  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white">
              <AiOutlineAlignRight />
            </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
              <li class="nav-item">
              {userAddress ? (
                <a class="nav-link main-nav-connect-button" onClick={dashboardpage}>
                  Dashboard
                </a>
              ):(
                <a></a>
              )}
              </li>
              
              <li class="nav-item">
              {userAddress ? (
                <a class="nav-link main-nav-connect-button">{userAddress}</a>
              ) : (
                <a class="nav-link main-nav-connect-button" onClick={handleShow}>
                  Connect Wallet
                </a>
              )}
              </li>
              <div className="modal-section">
                <WagmiConfig config={wagmiConfig}>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="d-flex justify-content-center"
                  >
                    <Modal.Header closeButton></Modal.Header>

                    <Modal.Body>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleClose();
                          metamaskconnect();
                        }}
                        className="connect-metamask-button"
                      >
                        <img src={Metamask} style={{ width: "30px" }} alt="" />
                        Connect Metamask
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => {
                          walletopen();
                          handleClose();
                          connectAddress();
                        }}
                        className="connect-metamask-button ms-3"
                      >
                        <img
                          src={Connectwallet}
                          style={{ width: "30px" }}
                          className="me-2"
                          alt=""
                        />
                           Trust Wallet
                      </Button>
                    </Modal.Body>
                  </Modal>
                </WagmiConfig>
                <Web3Modal
                  enableAccountView={true}
                  projectId={projectId}
                  ethereumClient={ethereumClient}
                />
              </div>

              <li class="nav-item">
                {userAddress ? (
                  <a class="nav-link main-nav-connect-button" onClick={valobitlogout}>
                    <AiOutlinePoweroff
                    className="me-2"
                    style={{ fontSize: "18px" }}
                    />
                    Logout
                  </a>
                ) : (
                  <a></a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section class="dashboard-values-design">
        <div class="container-fluid col-lg-11">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div className="register-card-design">
                <div className="d-flex flex-lg-row gap-3 mb-3 mt-5 justify-content-center">
                   <div className="text-center ref-input">
                    {userexiststatus == true ? (
                      ""
                    ) : (
                      <div className="d-flex flex-lg-row align-items-center mb-3 gap-3 justify-content-center dashboard-search-section">
                        <p className="mb-0">Referral ID</p>
                        <div
                          class="d-flex align-items-center overrall-search-id-section"
                          role="search"
                        >
                          {isEnabled ? (
                            <input
                            class="form-control"
                            type="number"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={handleChange}
                            value={message}
                          />
                          ) : (
                            <input
                              class="form-control"
                              type="number"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={handleChange}
                              value={message}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <br />
                    <div className="d-flex flex-lg-row align-items-center gap-3 justify-content-center dashboard-search-section">
                      <p className="mb-0">Plan Details</p>
                      <div
                        class="d-flex align-items-center overrall-search-id-section"
                        role="search"
                      >
                        <p className="mb-0">{planamount} </p>
                      </div>
                    </div>

                    <p className="container-fluid d-flex col-lg-6 mb-1 mt-3">
                      {useridnotexist}
                    </p>
                  </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-3 mt-2 g-4 mb-5 col-lg-10 mx-auto">
                    <>
                      {Sbtdatatype.map((packageItem, index) => (
                        <div
                          className="col"
                          onClick={() =>
                            setpackagePolyAmount(
                              packageItem.joiningAMT,
                              packageItem.distributionAMT,
                              packageItem.package,
                              packageItem.durations
                            )
                          }
                        >
                          <div class="card dash-new-card">
                            <div class="card-body py-lg-3 text-center">
                              <div className="">
                                <p className="dashboard-text-8">
                                  {packageItem.package}
                                </p>
                                <p className="dashboard-text-9">
                                  {packageItem.joiningAMT} SBT
                                </p>
                                <p>{packageItem.durations} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                </div>
                {loader == true ? (
                  <button
                    class="nav-link main-nav-connect-button mx-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <button
                    class="nav-link main-nav-connect-button mx-auto"
                    style={{ width: "fit-content" }}
                    onClick={() => register()}
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sbtregister;
