import Logo from "./valobit.png";
import {
  AiOutlineCopy,
  AiOutlineSearch,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { MdGroups2 } from "react-icons/md";
import $ from "jquery";
import { useState, useEffect } from "react";
import Web3 from "web3";
import config from "./config/config";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import copy from "copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import sbtmlmstakingABI from "./ABI/sbtmlmstaking.json"



$(function () {
  $(".genealogy-tree ul").hide();
  $(".genealogy-tree>ul").show();
  $(".genealogy-tree ul.active").show();
  $(".genealogy-tree li").on("click", function (e) {
    var children = $(this).find("> ul");
    if (children.is(":visible")) children.hide("fast").removeClass("active");
    else children.show("fast").addClass("active");
    e.stopPropagation();
  });
});



function Sbtdashboard() {

  const navigate = useNavigate();

  const [userAddress, setUserAddress] = useState("0x00");
  const [directlength, setdirectlength] = useState(0);
  const [DirectIncome, setDirectIncome] = useState(0);
  const [LevelIncome, setLevelIncome] = useState(0);
  const [TotalEarning, setTotalEarning] = useState(0);
  const [referralidvalue, setreferralidvalue] = useState(0);
  const [AllParticipant, setAllParticipant] = useState(0);
  const [inputSearchval, setinputSearchval] = useState("");
  const [ParentId, setParentId] = useState(0);
  const [genealogyData, setgenealogyData] = useState([]);
  const [Parenttreeid, setParenttreeid] = useState();
  const [userselectPackage, setuserselectPackage] = useState([]);
  const [Yourreward, setYourreward] = useState(0);
  const [PoolsDetails,setPoolsDetails] = useState(0);
  const [RewardErr,setRewardErr] = useState(false)
  const [rewardpopup,setrewardpopup] = useState(0);

  const [rewarddisable,setrewarddisable] = useState(0);

  const packamounts = async () => {
    if(localStorage.getItem("address")){
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const getusraddr = await axios.get(`${config.BACKEND_URL}sbtuserpackage?address=${accounts[0]}`);
      let lengpack = getusraddr.data.data;
      if(lengpack.length >0) {
        setuserselectPackage(getusraddr.data.data);
      }
    }
    else{
      let usersAddress = localStorage.getItem("wagmi.store");
      let userMeta = JSON.parse(usersAddress);
      let addr = userMeta.state.data.account;
      const getusraddr = await axios.get(
        `${config.BACKEND_URL}sbtuserpackage?address=${addr}`
      );
      let lengpack = getusraddr.data.data;
      if(lengpack.length >0) {
        setuserselectPackage(getusraddr.data.data);
      }
    }
  };


  const hideUserRewad = async(addr) => {
    const response = await axios.post(
      `${config.BACKEND_URL}hideUserRewad`,{addr:addr,network:"sbt"});
      console.log(response.data.data);
    if(response.data.data == "enable") {
      setrewarddisable(1)
    }
    else {
      setrewarddisable(0)
    }
  }


  const findUserId = async (addr) => {

    let web3js = new Web3(config.rpcUrls);
    let stakeContract = new web3js.eth.Contract(
      sbtmlmstakingABI,
      config.SBT_STAKING_ADDRESS
    );
    
    let allparti = await stakeContract.methods.lastUserId().call();
    let refidvalue = await stakeContract.methods.users(addr).call();

    setreferralidvalue(parseInt(refidvalue.id));
    setParentId(parseInt(refidvalue.id));
    setAllParticipant(parseInt(allparti) - 1);
    let directrefLen = await stakeContract.methods
      .directreferral(addr)
      .call();
    setdirectlength(directrefLen.length);

    let directincome = await stakeContract.methods
      .directIncome(addr)
      .call();
    let diretamount = await web3js.utils.fromWei(directincome, "ether");
    setDirectIncome(Number(diretamount));
    let lvlincome = await stakeContract.methods
      .levelIncome(addr)
      .call();
    let lvlamount = await web3js.utils.fromWei(lvlincome, "ether");
    setLevelIncome(Number(lvlamount));
    let number = Number(diretamount) + Number(lvlamount);
    let nos = number.toString();
    let numbers = nos.substring(0, 8);
    setTotalEarning(numbers)
  };


  const findDirectref = async (addr) => {
    let web3js = new Web3(config.rpcUrls);
    let stakeContract = new web3js.eth.Contract(
      sbtmlmstakingABI,
      config.SBT_STAKING_ADDRESS
    );
    let refidvalueparent = await stakeContract.methods.users(addr).call();
    setParenttreeid(parseInt(refidvalueparent.id));

    let inputVal = parseInt(refidvalueparent.id);

    if (inputVal !== 0) {
      const response = await axios.get(
        `${config.BACKEND_URL}getSbtUsersDetails?userID=${inputVal}`
      );
      let referenceID1 = [];
      if (response.data.status) {
        for (const trees of response.data.data) {
          setParenttreeid(response.data.showUserId1);
          const children1 = [];
          for (const trees1 of trees.childUsers) {
            const children2 = [];
            for (const trees2 of trees.grandchildUsers) {
              const children3 = [];
              for (const trees3 of trees.greatgrandchildUser) {
                const children4 = [];

                if (trees2.userID === trees3.referenceID) {
                  children3.push({
                    userID: trees3.userID,
                    children: children4,
                  });
                }
              }
              if (trees1.userID === trees2.referenceID) {
                children2.push({
                  userID: trees2.userID,
                  referenceID: trees2.referenceID,
                  children: children3,
                });
              }
            }
            if (trees.userID === trees1.referenceID) {
              children1.push({
                userID: trees1.userID,
                children: children2,
              });
            }
          }
          referenceID1.push({
            userID: trees.userID,
            children: children1,
          });
        }
        setgenealogyData(referenceID1);
      }
    }
  };

  const dsts = [
    {
      userID: Parenttreeid,
      children: genealogyData,
    },
  ];

  const updatenewamount = async(addr) => {
    let web3js = new Web3(config.rpcUrls);
    let stakeContract = new web3js.eth.Contract(
      sbtmlmstakingABI,
      config.SBT_STAKING_ADDRESS
    );
    let stak = await stakeContract.methods.getuserstake(addr).call();
      if(stak[0].length > 0) {
        let stakingrewards = []
        let stkingsrwd = []
        for(let i =0;i<stak[0].length;i++) {
          let stakeamount =  web3js.utils.fromWei(stak[0][i], "ether");
          let rewardsamount = web3js.utils.fromWei(stak[1][i], "ether");
          let stakingarray = {
            "stakingamounts" : stakeamount,
            "rewardsamounts" : rewardsamount
          }
          stakingrewards.push(stakingarray)
          stkingsrwd.push(rewardsamount)
        }
        setPoolsDetails(stakingrewards)
        await axios.post(
          `${config.BACKEND_URL}updateuserstakeamt`,{stakingamt: stkingsrwd,useraddr:addr,network:"sbt"});
      }
  }

  useEffect(() => {
    if (localStorage.getItem('address')) {
      packamounts();
      swicthnetwork();
      let prms = localStorage.getItem("parentId");
      if (prms !== null) {
        localStorage.removeItem("parentId");
      }
      const gettreestru = async () => {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        hideUserRewad(accounts[0])
        findDirectref(accounts[0]);
        findUserId(accounts[0]);
        updatenewamount(accounts[0])
        let userMetaAddress = localStorage.getItem("address");
        if (userMetaAddress != null) {
          setUserAddress(
            userMetaAddress.substring(0, 5) +
            "..." +
            userMetaAddress.substring(userMetaAddress.length - 4)
          );
        }
      }
      gettreestru();
    }
    else {
      let usersAddress = localStorage.getItem("wagmi.store");
      let userMeta = JSON.parse(usersAddress);
      let addr = userMeta.state.data.account;
      packamounts();
      let prms = localStorage.getItem("parentId");
      if (prms !== null) {
        localStorage.removeItem("parentId");
      }
      const gettreestru = async () => {
        hideUserRewad(addr)
        findDirectref(addr);
        findUserId(addr);
        updatenewamount(addr)
        if (addr != null) {
        setUserAddress(
          addr.substring(0, 5) + "..." + addr.substring(addr.length - 4)
        );
      }
      }
      gettreestru();
    };
   
  }, []);

  const valobitlogout = async () => {
    localStorage.clear();
    navigate("/");
  };

  const swicthnetwork = async() => {
    var web3js = new Web3(config.rpcUrls);
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3js.utils.toHex(config.chainId) }]
    });
  }
  
  const claimReward = async() => {
    swicthnetwork()
    let web3js = new Web3(window.ethereum);
    let amount = await web3js.eth.getBalance(config.SBT_STAKING_ADDRESS);
    let stakereward = web3js.utils.fromWei(amount, "ether");
      if(parseInt(rewardpopup) < parseInt(stakereward)) {
        if(rewardpopup > 0 ) {
          let stakeContract = new web3js.eth.Contract(sbtmlmstakingABI,config.SBT_STAKING_ADDRESS);
          if (localStorage.getItem('address')) {
            let userMetaAddress = localStorage.getItem("address");
            let amt = await stakeContract.methods.claimReward().send({
              from: userMetaAddress
            });
            updatenewamount(userMetaAddress)
          }
          else {
            let usersAddress = localStorage.getItem("wagmi.store");
            let userMeta = JSON.parse(usersAddress);
            let addr = userMeta.state.data.account;
            await stakeContract.methods.claimReward().send({
              from: addr
            })
            updatenewamount(addr)
          }
        }
        else {
          toast.error("Already claimed");
        }
      }
      else {
        toast.error("Try again sometime");
      }
  }


  const getstakes = async (address) => {
    try {
      let web3js = new Web3(config.rpcUrls);
      let stakeContract = new web3js.eth.Contract(
        sbtmlmstakingABI,
        config.SBT_STAKING_ADDRESS
      );
      let amts = await stakeContract.methods.getreward(address).call()
      let stakereward = web3js.utils.fromWei(amts, "ether");
      setrewardpopup(stakereward)
      setYourreward(Number(stakereward).toFixed(6))
    } catch (error) {
        setRewardErr(true)
    }
  }


  const userexists = async(address) => {
    let web3js = new Web3(config.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sbtmlmstakingABI,
      config.SBT_STAKING_ADDRESS
    );
    let userexists = await sunflowerContract.methods.isUserExists(address).call();
    if(!userexists) {
      navigate('/sbtregister')
    }
  }


  useEffect(() => {
    if (localStorage.getItem('address')) {
      let address = localStorage.getItem('address')
      userexists(address)
        const stakeamounts = async () => {
          const intervalId = setInterval(() => {
            getstakes(address)
          }, 20000);
        }
        stakeamounts()
    }
    else {
      let usersAddress = localStorage.getItem("wagmi.store");
      let userMeta = JSON.parse(usersAddress);
      let addr = userMeta.state.data.account;
      userexists(addr)
        const stakeamounts = async () => {
          if (addr) {
            const intervalId = setInterval(() => {
              getstakes(addr)
            }, 20000);
          }
        }
        stakeamounts()
    }
  })

  const copyLink = () => {
    let refLink = config.FRONTEND_URL + `sbtregister/${referralidvalue}`;
    copy(refLink);
    toast.success("Copied");
  };

  const searchChange = async (event) => {
    event.preventDefault();
    setinputSearchval(event.target.value);
  };

  const submitSearch = async (event) => {
    event.preventDefault();
    let inputVal = Number(inputSearchval);
    if (inputVal !== 0) {
      const response = await axios.get(
        `${config.BACKEND_URL}getSbtUsersDetails?userID=${inputSearchval}`
      );
     
      let referenceID1 = [];
      if (response.data.status) {
        for (const trees of response.data.data) {
          setParenttreeid(response.data.showUserId1);
          const children1 = [];
          for (const trees1 of trees.childUsers) {
            const children2 = [];
            for (const trees2 of trees.grandchildUsers) {
              const children3 = [];
              for (const trees3 of trees.greatgrandchildUser) {
                const children4 = [];

                if (trees2.userID === trees3.referenceID) {
                  children3.push({
                    userID: trees3.userID,
                    children: children4,
                  });
                }
              }
              if (trees1.userID === trees2.referenceID) {
                children2.push({
                  userID: trees2.userID,
                  referenceID: trees2.referenceID,
                  children: children3,
                });
              }
            }
            if (trees.userID === trees1.referenceID) {
              children1.push({
                userID: trees1.userID,
                children: children2,
              });
            }
          }
          referenceID1.push({
            userID: trees.userID,
            children: children1,
          });
        }
        setgenealogyData(referenceID1);
      } else {
        if (response.data.data == 0) {
          Swal.fire({
            background: "#1b1049",
            border: "1px solid #00e5ff21",
            color: "#ffffff",
            title: response.data.message,
          });
        }
        else {
          setParenttreeid(response.data.data);
          setgenealogyData();
        }
      }
    } else {
      Swal.fire({
        background: "#1b1049",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "There is no user",
      });
    }
  };



  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon">
              <HiOutlineMenuAlt3 className="text-white navbar-toggler-icon-new" />
            </span>
          </button>
         
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
            {rewarddisable == 1 ? (
              <li class="nav-item" >
                <div>
                  <a class="nav-link main-nav-connect-button" onClick={claimReward}><center>Claim</center></a>
                  <p className="mb-0 floating-nav-link-top">Total reward: {Yourreward} VBIT</p>
                  
                </div>
              </li>):(
                <div></div>
            )}
              <li class="nav-item">
                <a class="nav-link main-nav-connect-button">{userAddress}</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link main-nav-connect-button"
                  onClick={valobitlogout}
                >
                  <AiOutlinePoweroff
                    className="me-2"
                    style={{ fontSize: "18px" }}
                  />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section class="dashboard-values-design">
        <div class="container-fluid col-lg-11">
          <div className="d-flex flex-lg-row flex-column gap-lg-3 mb-3 mt-5">
            <div className="dasboard-tabs-section">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Dashboard
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Pools
                  </button>
                </li>
              </ul>
            </div>
            <div className="ms-lg-auto text-lg-end">
              <p className="dashboard-text-2 mb-0 border-bottom-referral">
                Referral link :
                <span
                  className="ms-2 dashboard-text-3"
                  style={{ color: "#6a68ef" }}
                >
                  {config.FRONTEND_URL}sbtregister/{referralidvalue}
                </span>
                <a class="ms-2 copy-button-dashboard" onClick={copyLink}>
                  <AiOutlineCopy />
                </a>
              </p>
            </div>
          </div>

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <div class="row g-4">
                <div class="col-lg-3">
                  <div class="card dash-new-card">
                    <div class="card-body">
                      <div className="dash-border-bottom">
                        <p className="dashboard-text-4">
                          My ID {referralidvalue}
                        </p>
                      </div>
                      <div className="dash-border-bottom">
                        <p className="mt-3 mb-1 dashboard-text-4">
                          Direct Referral
                        </p>
                        <p className="dashboard-text-4">
                          <MdGroups2 className="me-3" />
                          <span>{directlength}</span>
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="mt-3 mb-2 dashboard-text-4-4">
                          Level Income
                        </p>
                        <p className="mb-0 dashboard-text-4">
                          {" "}
                          {LevelIncome} VBIT
                        </p>
                      </div>
                      <div className="mt-2">
                        <p className="mt-3 mb-2 dashboard-text-4-4">
                          Direct Income
                        </p>
                        <p className="mb-0 dashboard-text-4">
                          {" "}
                          {DirectIncome} VBIT
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div class="card dash-new-card">
                        <div class="card-body py-lg-4 text-center">
                          <div className="">
                            <p className="dashboard-text-6">Total Earning</p>
                            <p className="dashboard-text-7">
                              {TotalEarning} VBIT
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {userselectPackage &&
                      userselectPackage.length > 0 &&
                      userselectPackage.map((data, index) => (
                        <div className="col-lg-6">
                          <div class="card dash-new-card">
                            <div class="card-body py-lg-4 text-center">
                              <div className="">
                                <p className="dashboard-text-6">
                                  Package {index + 1}
                                </p>
                                <p className="dashboard-text-7">
                                  {data.amount} VBIT
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  
                  <div className="row row-cols-1 row-cols-lg-3 mt-2 g-4 mb-5"></div>
                  <div className="row dashboard-search-section mb-3">
                    <div className="d-flex flex-lg-row align-items-center gap-3 justify-content-center">
                      <p className="mb-0">Search by ID</p>
                      <form
                        class="d-flex align-items-center overrall-search-id-section"
                        role="search"
                      >
                        <input
                          class="form-control"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          onChange={searchChange}
                          onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);

                            // Allow only numeric values (0-9) and the backspace key (8)
                            if (!/^[0-9\b.]+$/.test(keyValue)) {
                              event.preventDefault();
                            }
                          }}
                        />


                        <button
                          class="border-0 bg-transparent text-white"
                          onClick={submitSearch}
                        >
                          <AiOutlineSearch />
                        </button>
                      </form>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Tree data={dsts} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div class="row g-4 justify-content-center">
              <h2 className="fw-bold text-center mb-4">Staking detail</h2>
              {PoolsDetails &&
                PoolsDetails.length > 0 &&
                PoolsDetails.map((data, index) => (
                <div class="col-lg-4">
                  <div class="card dash-new-card py-lg-5">
                    <div class="card-body">
                      <div className="row justify-content-evenly">
                        <div className="col-5">
                          <div className="d-flex">
                            <p className="mt-3 mb-0 dashboard-text-5">
                              Amount staked
                            </p>
                            <p className="mt-3 mb-0 dashboard-text-5 ms-auto">
                              :
                            </p>
                          </div>
                        </div>
                        <div className="col-5">
                          <p className="mt-3 mb-0 dashboard-text-5">
                            {data.stakingamounts}
                          </p>
                        </div>
                      </div>
                      <div className="row justify-content-evenly">
                        <div className="col-5">
                          <div className="d-flex">
                            <p className="mt-3 mb-0 dashboard-text-5">
                              Locked Duration
                            </p>
                            <p className="mt-3 mb-0 dashboard-text-5 ms-auto">
                              :
                            </p>
                          </div>
                        </div>
                        <div className="col-5">
                          <p className="mt-3 mb-0 dashboard-text-5">
                          {userselectPackage[index]?userselectPackage[index].package:"150 months"}
                          </p>
                        </div>
                      </div>
                      <div className="row justify-content-evenly">
                        <div className="col-5">
                          <div className="d-flex">
                            <p className="mt-3 mb-0 dashboard-text-5">
                              Total reward earned
                            </p>
                            <p className="mt-3 mb-0 dashboard-text-5 ms-auto">
                              :
                            </p>
                          </div>
                        </div>
                        <div className="col-5">
                          <p className="mt-3 mb-0 dashboard-text-5">
                            {Number(data.rewardsamounts).toFixed(6)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sbtdashboard;

const Tree = ({ data }) => {
  return (
    <div className="genealogy-body genealogy-scroll">
      <div className="genealogy-tree">
        <ul>
          {data.map((node) => (
            <TreeNode key={node.userID} node={node} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const TreeNode = ({ node }) => {
  const hasChildren = node.children && node.children.length > 0;
  const [showchildren, setShowChildren] = useState(true);

  const toggleShowChildren = () => {
    setShowChildren(!showchildren);
    $(`#node-${node.userID} > ul`).toggle();
  };

  return (
    <li>
      <a href="javascript:void(0);">
        <div className="member-view-box" onClick={toggleShowChildren}>
          <div className="member-image">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div>
                <BsFillPersonFill class="tree-person-icon" />
              </div>
            </div>
            <div className="ms-auto text-center">
              <span className="">
                <span className="me-1">ID</span>
                {node.userID}
              </span>
            </div>
          </div>
        </div>
      </a>

      {showchildren && hasChildren && (
        <ul>
          {node.children.map((child) => (
            <TreeNode key={child.id} node={child} />
          ))}
        </ul>
      )}
    </li>
  );
};
