import Logo from "./valobit.png";
import sbtlogo from "./assets/images/sbttoken.png"
import { IoWallet } from "react-icons/io5";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { BsArrowRightCircle } from "react-icons/bs";
import Polygon from "./assets/images/polygon.png";
import { useEffect, useState } from "react";
import config from "./config/config";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Network.css";
import { Web3NetworkSwitch } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, polygon, bsc } from "wagmi/chains";
import {  useWeb3Modal } from "@web3modal/react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Metamask from "./assets/images/Metamask.png";
import Connectwallet from "./assets/images/trustwallet.png";
import backendConfig from "./config/config";
import $ from "jquery";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import ETH from './switchnetwork'
import Matic from '../src/matic'
import sunflowerABI from "./ABI/vbitmlm.json";

function Network() {

  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState("");
  const [selectNetwork, setSelectNetwork] = useState("VAL");
  const [loader, setLoader] = useState(false);
  const [parentIdValue, setParentIdValue] = useState("");
  const [connected, setConnected] = useState(false);
  const [chain, setChain] = useState()

  const { open, close } = useWeb3Modal();

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const Home = () => {
    navigate("/");
    localStorage.clear();
    //  window.location.reload();
  };
  
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  let userAdd = localStorage.getItem("address");

  const chains = [polygon, ETH,Matic];
  const projectId = "1f0b9efb280db82bff5edc4689ffa76a";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });

  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  const networkRefresh = () => {
    setTimeout(() => {
      let chainid = localStorage.getItem("wc@2:ethereum_provider:/chainId")
      console.log(chainid, "jkldajfdja");
      if (chainid == config.VALOBIT_chainId || config.POLY_chainId) {
        window.location.reload()
      }
    }, 3000)
  };



  const metamaskconnect = async () => {
    if (window.ethereum) {
      let backchain = backendConfig.chainId;
      let metchainid = window.ethereum.chainId;
      if (backchain == metchainid) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);

        let address = localStorage.getItem("address");
        walletdetails();
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      } else {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let address = localStorage.getItem("address");
        walletdetails();
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      }
    } else {
      toast.warn("Please install metamask");
      openInNewTab(
        `https://metamask.io/download/`
      );
    }
  };


  const walletdetails = async () => {
    const ethereumAddress = wagmiConfig.ethereumAddress;
    let result = localStorage.getItem("address");
    if (result) {
      let address = result;
      let web3js = new Web3(backendConfig.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(
        sunflowerABI,
        backendConfig.BKC_MLMContract
      );
      let userexists = await sunflowerContract.methods
        .isUserExists(address)
        .call();
      if (userexists == true) {
        $("#buypackagehide").show();
      } else {
        console.log("fasle")
      }
    }
  };

  const walletopen = async () => {
    let data = await open();
    setInterval(() => {
      // console.log("i'm calling")
      if (localStorage.getItem("wagmi.connected")) {
        close();
        window.location.reload(true);
      }
    }, 2000);
  };

  


  useEffect(() => {
    const params = window.location.href.split("/")[4];
    if (params == undefined) {
      setParentIdValue("")
    }
    else {
      localStorage.setItem("parentId", params);
      setParentIdValue(params)
    }
  })

  const addNetwork = async (token) => {
    try {
      if (token == 1) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: config.chainId,
              chainName: "VALOBIT",
              rpcUrls: [config.rpcUrls],
              blockExplorerUrls: [config.BKC_ContarctLink],
              nativeCurrency: {
                name: "VALOBIT",
                symbol: "VBIT",
                decimals: 18,
              },
            },
          ],
        });
        setSelectNetwork("VAL");
      } else if (token == 2) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: config.polygon_chainId,
              chainName: "Matic",
              rpcUrls: [config.polygon_rpcUrls],
              blockExplorerUrls: [config.polygon_ContarctLink],
              nativeCurrency: {
                name: "Matic",
                symbol: "MATIC",
                decimals: 18,
              },
            },
          ],
        });
        setSelectNetwork("POLYGON");
      }
      else if(token == 3) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: config.chainId,
              chainName: "VALOBIT",
              rpcUrls: [config.rpcUrls],
              blockExplorerUrls: [config.BKC_ContarctLink],
              nativeCurrency: {
                name: "VALOBIT",
                symbol: "VBIT",
                decimals: 18,
              },
            },
          ],
        });
        setSelectNetwork("SBT");
      }
    } catch (error) {
      console.log("addNetwork error", error);
    }
  };



  const connectAddress = async () => {
    if (localStorage.getItem("address")) {
      if (window.ethereum) {
        addNetwork(1);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        const address = accounts[0];
        setUserAddress(
          address.substring(0, 5) + "..." + address.substring(address.length - 4)
        );
      } else {
        window.open(config.metmaskurl, "_blank");
      }
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;
      const address = addr

      if (addr != null) {
        setUserAddress(
          address.substring(0, 5) + "..." + address.substring(address.length - 4)
        );
        console.log(userAddress, 'userAddress');
      }
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };



  useEffect(() => {
    if (localStorage.getItem("address")) {
      if (window.ethereum) {
        addNetwork(1);
        window.ethereum.on("accountsChanged", function (accounts) {
          addNetwork(1);
          localStorage.setItem("address", accounts[0]);
          const address = accounts[0];
          setUserAddress(
            address.substring(0, 5) + "..." + address.substring(address.length - 4)
          );
        });
        if (userAdd == "undefined") {
          connectAddress();
        }
        if (userAdd != null) {
          setUserAddress(
            userAdd.substring(0, 5) + "..." + userAdd.substring(userAdd.length - 4)
          );
        }
      }
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;
      const address = addr

      if(address){
        setUserAddress(
          address.substring(0, 5) + "..." + address.substring(address.length - 4)
        );
      }
     
    }
  }, [userAdd]);

  


  useEffect(() => {
    const connect = localStorage.getItem("wagmi.connected")
    setConnected(connect)

    if (localStorage.getItem("address")) {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        async function fetchChainId() {
          try {
            const chainID = await window.ethereum.chainId;
            if (chainID == config.chainId) {
              setSelectNetwork("VAL");
            } else if (chainID == config.polygon_chainId) {
              setSelectNetwork("POLYGON");
            }
          } catch (error) {
            console.log("Error fetching chain ID:", error);
          }
        }
        window.ethereum.on("chainChanged", () => {
          fetchChainId();
        });
        fetchChainId();
      }
      else {
        toast.error("Metamask Not detected");
      }
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;
      const address = addr
      
      if(address){
        const userMetaAddress = localStorage.getItem("wagmi.store");
        const userMeta = JSON.parse(userMetaAddress);
        const chain = userMeta.state.data.chain.id;
        setChain(chain)
        try {
          if (chain == config.POLY_chainId) {
            setSelectNetwork("POLYGON");
          } else if (chain == config.VALOBIT_chainId) {
            setSelectNetwork("VAL");
          }
          else if(chain == config. MATIC_chainId){
            setSelectNetwork("POLYGON")
          }
        } catch (error) {
          console.log("Error fetching chain ID:", error);
        }
    }
  }

  }, []);

  const changeToken = async (num) => {
    try {
      if (num === 1) {
        await addNetwork(2);
      }
      if (num === 2) {
        await addNetwork(1);
      }
      if(num === 3) {
        await addNetwork(3);
      }
    } catch (error) {
      console.log("changeToken error", error);
    }
  };

  const register = async (event) => {
    if(event == "SBT") {
      navigate("/sbtregister");
    }
    else {
      navigate(`/register/${event}`);
    }

  }

  const disconnect = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (

    <div className="App">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon">
              <HiOutlineMenuAlt3 className="text-white navbar-toggler-icon-new" />
            </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
              <li class="nav-item">
                {userAddress != "" ? (
                  <a class="nav-link main-nav-connect-button">
                    <IoWallet className="me-2 main-nav-connect-button-icon" />
                    {userAddress}
                  </a>
                ) : (
                  <a
                    class="nav-link main-nav-connect-button"
                    // onClick={connectAddress}
                    onClick={handleShow}
                  >
                    <IoWallet className="me-2 main-nav-connect-button-icon"  />
                    Connect wallet
                  </a>
                )}
              </li>
              <div className="modal-section">
                <WagmiConfig config={wagmiConfig}>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="d-flex justify-content-center"
                  >
                    <Modal.Header closeButton></Modal.Header>

                    <Modal.Body>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleClose();
                          metamaskconnect();
                        }}
                        className="connect-metamask-button"
                      >
                        <img src={Metamask} style={{ width: "30px" }} alt="" />
                        Connect Metamask
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => {
                          walletopen();
                          handleClose();
                          connectAddress();
                        }}
                        className="connect-metamask-button ms-3"
                      >
                        <img
                          src={Connectwallet}
                          style={{ width: "30px" }}
                          className="me-2"
                          alt=""
                        />
                           Trust Wallet
                      </Button>
                    </Modal.Body>
                  </Modal>
                </WagmiConfig>
                <Web3Modal
                  enableAccountView={true}
                  projectId={projectId}
                  ethereumClient={ethereumClient}
                />
              </div>
              <li>
                {userAddress ? (
                  <button class="main-nav-connect-button" onClick={disconnect}>
                    Disconnect
                  </button>
                ) : (
                  <a></a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

     

       <section class="hero-section-banner hero-section-network-banner">
        <div class="container-fluid col-lg-11">
          <div class="glow-bg-1"></div>
          <div class="row banner-min-height-desktop flex-lg-row-reverse">
            <div class="col-lg-12 text-center">
              <br />
              {connected ? (
                <>
                  <WagmiConfig config={wagmiConfig}></WagmiConfig>
                  <center>
                    <Web3NetworkSwitch onClick={networkRefresh} />
                  </center>
                  <center>
                    {chain === config.VALOBIT_chainId || chain === config.POLY_chainId ? (
                       <></>
                    ) : (
                     <span className="switch-banner-section-text-2">
                     NOTE: Please switch your Valobit network{" "} 
                     <a href="#" onClick={togglePopup}>
                      <span style={{color:"red"}}>Click Here</span>
                       
                     </a> or polygon network

                     {isPopupVisible && (
                       <div className="popup">
                         <>
                           <Modal show={isPopupVisible} onHide={hidePopup}>
                             <Modal.Header closeButton>
                               <Modal.Title>Valobit Network</Modal.Title>{" "}
                               <br />
                               <br />
                               <br />
                             </Modal.Header>
                             <Modal.Body>
                               Name:VALOBIT Mainnet <br />
                               <br />
                               Symbol:VBIT <br />
                               <br />
                               NodeURL:{config.valobit} <br />
                               <br />
                               ExplorerURL:{config.valobit_explorer_url}
                             </Modal.Body>
                             <Modal.Footer style={{ color: "red" }}>
                               Please Add Valobit Network and Reconnect the
                               Wallet
                               <Button
                                 variant="primary"
                                 onClick={() => {
                                   Home();
                                   hidePopup();
                                 }}
                               >
                                 OK
                               </Button>
                             </Modal.Footer>
                           </Modal>
                         </>
                       </div>
                     )}
                   </span>
                 )}
                    
                  </center>
                </>
              ) : (
                <div class="dropdown">
                  <button
                    class="btn btn-network-select dropdown-toggle w-auto"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    NETWORK - {selectNetwork}
                  </button>
                  <ul
                    class="dropdown-menu "
                    aria-labelledby="dropdownMenuButton1"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active w-100"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected={`${selectNetwork == "POLYGON" ? "true" : "false"
                          }`}
                        onClick={() => changeToken(1)}
                      >
                        <div className="d-flex flex-row ">
                          <span>Polygon</span>
                          <span className="ms-auto">
                            <img
                              className="dropdown-network-img"
                              src={Polygon}
                              alt="Polygon"
                            />
                          </span>
                        </div>
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link w-100"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected={`${selectNetwork == "VAL" ? "true" : "false"
                          }`}
                        onClick={() => changeToken(2)}
                      >
                        <div className="d-flex flex-row ">
                          <span>Vbit</span>
                          <span className="ms-auto">
                            <img
                              className="dropdown-network-img"
                              src={Logo}
                              alt="Vbit"
                            />
                          </span>
                        </div>
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link w-100"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected={`${selectNetwork == "SBT" ? "true" : "false"
                          }`}
                        onClick={() => changeToken(3)}
                      >
                        <div className="d-flex flex-row ">
                          <span>SBT</span>
                          <span className="ms-auto">
                            <img
                              className="dropdown-network-img"
                              src={sbtlogo}
                              alt="SBT"
                            />
                          </span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              )} <br />

              {selectNetwork == "POLYGON" ? (
                <>
                  <div className="card network-card-box">
                    <p className="mb-0 network-text-1">
                      poly vbit token
                    </p>
                    <a onClick={() => register("poly")}
                      className="text-center network-text-2 mb-0"
                    >
                      <BsArrowRightCircle />
                    </a>
                  </div>
                </>
              ) :selectNetwork == "SBT" ? (
                <>
                  <div className="card network-card-box">
                    <p className="mb-0 network-text-1">SBT Token</p>
                    <a onClick={() => register("SBT")}
                      className="text-center network-text-2 mb-0"
                    >
                      <BsArrowRightCircle />
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="card network-card-box">
                    <p className="mb-0 network-text-1">vbit coin</p>
                    <a onClick={() => register("VAL")}
                      className="text-center network-text-2 mb-0"
                    >
                      <BsArrowRightCircle />
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section> 
    </div>
  );
}

export default Network;
