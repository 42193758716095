let config = {};
let environment = "live";
if (environment === "local") {
  config = {
    POLYGON_Contract: "0x538ae099d56f35fad0b4d66c904cfc03df8961d4",
    BKC_MLMContract: "0xDE08456701064D8aF442c0c9E290fb90f3E7DE51",
    stakingContract: "0x509784d9D0DDDA476754786c15e56130F1166E7C",
    claimrewardContract : "0xD74b38D080935044f91206B2A90eC4Ccbe8Aa08A",
    BACKEND_URL:"http://localhost:4000/",
    FRONTEND_URL:"http://localhost:3000/",
    chainId: "0x918",
    polygon_chainId: "0x13881",
    rpcUrls: "https://block.valobittestnet.com/",
    polygon_rpcUrls: "https://rpc-mumbai.maticvigil.com",
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://testnet.valobittestnet.com",
    polygon_ContarctLink: "https://mumbai.polygonscan.com",

    POLY_chainId: 137,
    VALOBIT_chainId: 2328,
    MATIC_chainId :80001,
    valobit: "https://block.valobittestnet.com/",
    valobit_explorer_url: "https://testnet.valobittestnet.com",
    SBT_TOKEN_ADDRESS :"0x94aE8241839b9DFD63A1bf71f5deE781a478a680",
    SBT_STAKING_ADDRESS:"0xc277e0A7963b96cbE4ed60B28d37B3A5e88b4672"
  };
} else if (environment === "deployment") {
  config = {
    POLYGON_Contract: "0x538ae099d56f35fad0b4d66c904cfc03df8961d4",
    BKC_MLMContract: "0xDE08456701064D8aF442c0c9E290fb90f3E7DE51",
    stakingContract: "0x509784d9D0DDDA476754786c15e56130F1166E7C",
    claimrewardContract : "0xD74b38D080935044f91206B2A90eC4Ccbe8Aa08A",
    BACKEND_URL:"https://api.vbitdemo.com/",
    FRONTEND_URL:"https://vbitdemo.com/",
    chainId: "0x918",
    polygon_chainId: "0x13881",
    rpcUrls: "https://block.valobittestnet.com/",
    polygon_rpcUrls: "https://rpc-mumbai.maticvigil.com",
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://testnet.valobittestnet.com",
    polygon_ContarctLink: "https://mumbai.polygonscan.com",

    POLY_chainId: 137,
    VALOBIT_chainId: 2328,
    MATIC_chainId :80001,
    valobit: "https://block.valobittestnet.com/",
    valobit_explorer_url: "https://testnet.valobittestnet.com",
    SBT_TOKEN_ADDRESS :"0x94aE8241839b9DFD63A1bf71f5deE781a478a680",
    SBT_STAKING_ADDRESS:"0xc277e0A7963b96cbE4ed60B28d37B3A5e88b4672"
  };
} else {
  config = {
    POLYGON_Contract: "0x79E2913994c5378661283b63Aa5064991f738EA9",
    BKC_MLMContract: "0x19Fe238be8048A43EBBE66F8E9B0da76424568FD",
    stakingContract: "0x8dC6926b2be46989C84D8aB8ccD5049A4E054C47",
    claimrewardContract : "0x2C4dfC52600800B0B8dA43C03676788Ebd8947b3",
    BACKEND_URL:"https://api.valobitstake.com/",
    FRONTEND_URL:"https://valobitstake.com/",
    chainId: "0x9e7",
    polygon_chainId: "0x89",
    rpcUrls: "https://rpc.mnet.vblockchain.io/",
    polygon_rpcUrls: "https://polygon-rpc.com/",
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://valobitscan.io/",
    polygon_ContarctLink: "https://polygonscan.com",

    POLY_chainId: 137,
    VALOBIT_chainId: 2535,
    MATIC_chainId :137,
    valobit: "https://rpc.mnet.vblockchain.io/",
    valobit_explorer_url: "https://valobitscan.io/",
    SBT_TOKEN_ADDRESS :"0x11360f41187fba639ef6631e1f6c2fe96a9d8020",
    SBT_STAKING_ADDRESS:"0x15fd8FFA2e32D9203e695174B74aD7EC571D3c9C"
  };
}

export default config;