import Logo from "./valobit.png";
import {
  AiOutlineCopy,
  AiOutlineSearch,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { AiOutlineAlignRight } from "react-icons/ai";
import $ from "jquery";
import { useState, useEffect } from "react";
import Web3 from "web3";
import tokenABI from "./ABI/busd.json";
import sunflowerABI from "./ABI/vbitmlm.json";
import config from "./config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { EthereumProvider } from "@walletconnect/ethereum-provider";


var jq = $.noConflict();



function Register() {

  const navigate = useNavigate();

  const [isEnabled, setIsEnabled] = useState(true);
  const [userAddress, setUserAddress] = useState("0x00");
  const [message, setMessage] = useState("");
  const [parentAddress, setparentAddress] = useState("0x00");
  const [useridnotexist, setuseridnotexist] = useState("");
  const [networkStatus, setNetworkStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [parentIdValue, setParentIdValue] = useState("");
  const [Bkcdatatype, setBkcdatatype] = useState([]);
  const [Sbtdatatype, setSbtdatatype] = useState([]);
  const [Polydatatype, setPolydatatype] = useState([]);
  const [userenterBkcAmount, setuserenterBkcAmount] = useState("");
  const [userenterPolyAmount, setuserenterPolyAmount] = useState("");
  const [userdistributeBkcamount, setuserdistributeBkcamount] = useState("");
  const [userdistributePolyamount, setuserdistributePolyamount] =
    useState("");
  const [userpolypackage, setuserpolypackage] = useState("");
  const [userbkcpackage, setuserbkcpackage] = useState("");
  const [plantype, setplantype] = useState("");
  const [planamount, setplanamount] = useState("0 VBIT");
  const [plandurations,setplandurations] = useState("60");
  const [userexiststatus,setuserexiststatus] = useState(false)
  const [useridvalue,setuseridvalue] = useState();


  const parentaddresssetval = async (event) => {
    setMessage(event);
    try {
      let web3 = new Web3(config.rpcUrls);
      let sunflowerContract = new web3.eth.Contract(
        sunflowerABI,
        config.BKC_MLMContract
      );
      let findUserID = await sunflowerContract.methods.lastUserId().call();

      let activeUsers = parseInt(findUserID) - 1;

      if (activeUsers >= event) {
        setuseridnotexist("");
        let idBasedAddress = await sunflowerContract.methods
          .idToAddress(event)
          .call();
        setparentAddress(idBasedAddress);
      } else {
        setparentAddress();
        setuseridnotexist("User Id doesn't exist");
      }
    } catch (error) {
      console.log("findParentId error", error);
    }
  };


  const userdashboardshow = async (userMetaAddress) => {
    let web3js = new Web3(config.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      config.BKC_MLMContract
    );
   
    let userexists = await sunflowerContract.methods
      .isUserExists(userMetaAddress).call();
      
    if (userexists == true) {
      let idBasedAddress = await sunflowerContract.methods.users(userMetaAddress)
          .call();
      setparentAddress(idBasedAddress.referrer)
      let refeAddress = await sunflowerContract.methods.users(idBasedAddress.referrer)
          .call();
      setMessage(parseInt(refeAddress.id))
      setuserexiststatus(true);
      setuseridvalue(parseInt(idBasedAddress.id))
    } else {
      let findUserID = await sunflowerContract.methods.lastUserId().call();
        
      setuseridvalue(parseInt(findUserID))
      setuserexiststatus(false);
    }
  };


  const handleChange = async (event) => {
    const inputValue = event.target.value;
    console.log("inputvalue",inputValue);
    
    if (/^[1-9]\d*$/.test(inputValue)) {
      setMessage(inputValue);
      try {
        let web3 = new Web3(config.rpcUrls);
        let sunflowerContract = new web3.eth.Contract(
          sunflowerABI,
          config.BKC_MLMContract
        );
        let findUserID = await sunflowerContract.methods.lastUserId().call();
  
        let activeUsers = parseInt(findUserID) - 1;
        const inputValueAsInt = parseInt(inputValue);
        if (activeUsers >= inputValueAsInt) {
          let idBasedAddress = await sunflowerContract.methods
            .idToAddress(inputValueAsInt)
            .call();
          setparentAddress(idBasedAddress);
          setuseridnotexist(""); // Reset the error message
        } else {
          setparentAddress();
          setuseridnotexist("User Id doesn't exist");
        }
      } catch (error) {
        console.log("findParentId error", error);
      }
    }  else {
      setMessage("");
      setparentAddress();
      setuseridnotexist("User Id doesn't exist");
    }
  };
  
  
  

  const findParentId = async (message) => {
    try {
      let web3 = new Web3(config.rpcUrls);
      let sunflowerContract = new web3.eth.Contract(
        sunflowerABI,
        config.BKC_MLMContract
      );
      let findUserID = await sunflowerContract.methods.lastUserId().call();
      let activeUsers = parseInt(findUserID) - 1;
      if (activeUsers >= message) {
        let idBasedAddress = await sunflowerContract.methods
          .idToAddress(message)
          .call();
        setparentAddress(idBasedAddress);
      } else {
        setparentAddress();
        toast.error("User doesn't match..!");
      }
    } catch (error) {
      console.log("findParentId error", error);
    }
  };

  const getallplans = async () => {
    const getallbkc = await axios.get(
      `${config.BACKEND_URL}get-all-plans?network=Vibit`
    );
    setBkcdatatype(getallbkc.data.data);
    const getallpolygon = await axios.get(
      `${config.BACKEND_URL}get-all-plans?network=Polygon`
    );
    console.log("getallpolygon",getallpolygon);
    setPolydatatype(getallpolygon.data.data);
  };

  const getvbtpln = async() => {
    const getallbkc = await axios.get(
      `${config.BACKEND_URL}get-all-plans?network=Vibit`
    );
    setBkcdatatype(getallbkc.data.data);
    let bkcplns = getallbkc.data.data;
    return bkcplns;
  }

  

  const getpolyplan = async() => {
    const getallpolygon = await axios.get(
      `${config.BACKEND_URL}get-all-plans?network=Polygon`
    );
    setPolydatatype(getallpolygon.data.data);
    let polyplns = getallpolygon.data.data;
    return polyplns;
  }
  
  const getusrplan = async(useraddress) => {
    const getusraddr = await axios.get(
      `${config.BACKEND_URL}userpackage?address=${useraddress}`
    );
    let datlen = getusraddr.data.data;
    if(datlen.length > 0) {
      let arr = []
      for(let i=0; i < datlen.length; i++) {
        const startDate = new Date(datlen[i].dateTime);
        const currentDate = new Date();
        const timeDifference = currentDate - startDate;
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const daysPassed = Math.floor(timeDifference / millisecondsPerDay);
        const endDay = 60;
        const remainingDays = endDay - daysPassed;
        if(remainingDays > 0) {
          arr.push(datlen[i].package)
        }
      } 
      const params = window.location.href.split("/")[4];
      if (params === "VAL") {
        let datasplan = await getvbtpln()
        try {
          let pack = datasplan.filter(item => !arr.includes(item.package));
          setBkcdatatype(pack);
        } catch (error) {
          setBkcdatatype(datasplan);
        }
      }
      else {
        let datasplanpoly = await getpolyplan()
        try {
          let pack = datasplanpoly.filter(item => !arr.includes(item.package));
          setPolydatatype(pack)
        } catch (error) {
          setPolydatatype(datasplanpoly)
        }
      }
    }
    else {
      getallplans()
    }
  }

  useEffect(() => {
    let userMetaAddress = localStorage.getItem("address");
    let prms = localStorage.getItem("parentId");

    if(userMetaAddress){
      if (prms !== null) {
        setIsEnabled(!isEnabled);
        parentaddresssetval(prms);
      }
      const params = window.location.href.split("/")[4];
      const parentAdd = window.location.href.split("/")[5];
      setNetworkStatus(params);
      let addrlower = userMetaAddress.toLowerCase()
      getusrplan(addrlower)

      if (parentAdd !== undefined) {
        setParentIdValue(parentAdd);
        findParentId(parentAdd);
        setMessage(parentAdd);
      }

      if (userMetaAddress != null) {
        userdashboardshow(userMetaAddress)
        setUserAddress(
          userMetaAddress.substring(0, 5) +
            "..." +
            userMetaAddress.substring(userMetaAddress.length - 4)
        );
      }
    }
    else {
      let userMetaAddress = localStorage.getItem("wagmi.store");
      let userMeta = JSON.parse(userMetaAddress);
      let addr = userMeta.state.data.account;
      if(addr){
        getusrplan(addr)
        setUserAddress(
          addr.substring(0, 5) + "..." + addr.substring(addr.length - 4)
        );
      }
      let prms = localStorage.getItem("parentId");
      if (prms !== null) {
        setIsEnabled(!isEnabled);
        parentaddresssetval(prms);
      }
      const params = window.location.href.split("/")[4];
      const parentAdd = window.location.href.split("/")[5];
     
      setNetworkStatus(params);

      if (parentAdd !== undefined) {
        setParentIdValue(parentAdd);
        findParentId(parentAdd);
        setMessage(parentAdd);
      }
    }
  }, []);

  

  const valobitlogout = async () => {
    localStorage.clear();
    navigate("/");
  };



  const register = async () => {
      console.log("getitemaddr",localStorage.getItem("address"),networkStatus);
    if(localStorage.getItem("address")) {
    if (message == "" || parentAddress == undefined) {
      toast.error("Parent Id must");
    } else {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      let userAdd = accounts[0];
      let parentIdValue = message;
      if (networkStatus === "poly") {
        if(userenterPolyAmount > 0) {
          try {
            if (window.ethereum.chainId == config.polygon_chainId) {
              setLoader(true);
              const web3 = new Web3(window.ethereum);
              const polyContract = new web3.eth.Contract(tokenABI,config.POLYGON_Contract);
              let amount = await web3.utils.toWei(userenterPolyAmount, "ether");
              let transfer = await polyContract.methods
                .transfer("0x08A139458b4Ce2C022d17B078A8611BBe585b606", amount)
                .send({ from: userAdd });
              let payload = {
                user_address: userAdd,
                amount: userenterPolyAmount,
                userID : useridvalue,
                distributeamount: userdistributePolyamount,
                polygonpackage : plandurations,
                referenceID: `${parentIdValue}`,
                network: "polygon",
                package: userpolypackage,
              };
              const response = await axios.post(
                `${config.BACKEND_URL}add-users`,
                payload
              );
              toast.success("Register successfully");
              setLoader(false);
              navigate("/dashboard");
            } else {
              toast.error("Connect polygon");
            }
          } catch (error) {
            setLoader(false);
          }
        }
        else {
          toast.error("select Package");
        }
      }

      if (networkStatus === "VAL") {
        if(userenterBkcAmount > 0) {
          try {
            setLoader(true);
            const web3 = new Web3(window.ethereum);
           
            let durationsdays = parseInt(plandurations) * 24 * 60 * 60;
            let amount = await web3.utils.toWei(userenterBkcAmount, "ether");
  
            const mlmContract = new web3.eth.Contract(
              sunflowerABI,
              config.BKC_MLMContract
            );
            let refAddress;
            if (parentIdValue != "") {
              refAddress = await mlmContract.methods
                .idToAddress(parentIdValue)
                .call();
            } else {
              refAddress = await mlmContract.methods.idToAddress(1).call();
            }
            console.log("useridvalue--",useridvalue);
            if (userexiststatus == true) {
              let addplan = await mlmContract.methods.AddPlan(amount, durationsdays)
                .send({ 
                  from: userAdd,
                  value : amount
                 });
                let payload = {
                  user_address: userAdd,
                  amount: userenterBkcAmount,
                  distributeamount: userdistributeBkcamount,
                  referenceID: `${parentIdValue}`,
                  network: "VAL",
                  userID : useridvalue,
                  package: userbkcpackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/dashboard");
            } else {
              let transfer = await mlmContract.methods.register(refAddress, amount, durationsdays)
                .send({
                   from: userAdd,
                   value : amount
                  });
                let payload = {
                  user_address: userAdd,
                  amount: userenterBkcAmount,
                  distributeamount: userdistributeBkcamount,
                  referenceID: `${parentIdValue}`,
                  network: "VAL",
                  userID : useridvalue,
                  package: userbkcpackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/dashboard");
            }
          } catch (error) {
            setLoader(false);
          }
        }
        else {
          toast.error("select package");
        }
      }
    }
  }
  else{
    if (message == "" || parentAddress == undefined) {
      toast.error("Referral Id must");
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;
      
      const chain = userMeta.state.data.chain.id;
      const address = addr
      let parentIdValue = message;
    
      if (networkStatus === "poly") {
        if(userenterPolyAmount > 0) { 
          try {
            if (chain == config.POLY_chainId) {
              setLoader(true);
              const provider = await EthereumProvider.init({
                projectId :"1f0b9efb280db82bff5edc4689ffa76a",
                chains:[config.Bsc_chainId]
              })
              const web3 = new Web3(provider);
    
              const polyContract = new web3.eth.Contract(
                tokenABI,
                config.POLYGON_Contract
              );
              let amount = await web3.utils.toWei(userenterPolyAmount, "ether");
              let transfer = await polyContract.methods
                .transfer("0x08A139458b4Ce2C022d17B078A8611BBe585b606", amount)
                .send({ from: address });
              let payload = {
                user_address: address,
                amount: userenterPolyAmount,
                distributeamount: userdistributePolyamount,
                referenceID: `${parentIdValue}`,
                polygonpackage : plandurations,
                network: "polygon",
                package: userpolypackage,
                userID : useridvalue
              };
              const response = await axios.post(
                `${config.BACKEND_URL}add-users`,
                payload
              );
              toast.success("Register successfully");
              setLoader(false);
              navigate("/dashboard");
            } 
            else if(chain == config.MATIC_chainId){
              
                setLoader(true);
                const provider = await EthereumProvider.init({
                  projectId :"1f0b9efb280db82bff5edc4689ffa76a",
                  chains:[config.Bsc_chainId]
                })
                const web3 = new Web3(provider);
                const polyContract = new web3.eth.Contract(
                  tokenABI,
                  config.POLYGON_Contract
                );
                let amount = await web3.utils.toWei(userenterPolyAmount, "ether");
                let transfer = await polyContract.methods
                  .transfer("0x08A139458b4Ce2C022d17B078A8611BBe585b606", amount)
                  .send({ from: address });
      
                let payload = {
                  user_address: address,
                  amount: userenterPolyAmount,
                  distributeamount: userdistributePolyamount,
                  referenceID: `${parentIdValue}`,
                  network: "polygon",
                  userID : useridvalue,
                  polygonpackage : plandurations,
                  package: userpolypackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/dashboard");
            }
            else {
              toast.error("Connect polygon ");
            }
          } catch (error) {
            console.log("error while transfer", error);
            setLoader(false);
          }
        }
        else {
          toast.error("select Package");
        }
      }
      if (networkStatus === "VAL") {
        if(userenterBkcAmount > 0) {  
          try {
            setLoader(true);
            const provider = await EthereumProvider.init({
                projectId :"1f0b9efb280db82bff5edc4689ffa76a",
                chains:[config.Bsc_chainId]
              })
              const web3 = new Web3(provider);
  
            let durationsdays = parseInt(plandurations) * 24 * 60 * 60;
            let amount = await web3.utils.toWei(userenterBkcAmount, "ether");
    
            const mlmContract = new web3.eth.Contract(
              sunflowerABI,
              config.BKC_MLMContract
            );
            let refAddress;
            if (parentIdValue != "") {
              refAddress = await mlmContract.methods
                .idToAddress(parentIdValue)
                .call();
            } else {
              refAddress = await mlmContract.methods.idToAddress(1).call();
            }
    
            if (userexiststatus == true) {
              let addplan = await mlmContract.methods.AddPlan(amount, durationsdays)
                .send({ from: address ,
                  value : amount});
                let addres = address.toLowerCase()
                let payload = {
                  user_address: addres,
                  amount: userenterBkcAmount,
                  distributeamount: userdistributeBkcamount,
                  referenceID: `${parentIdValue}`,
                  network: "VAL",
                  userID : useridvalue,
                  package: userbkcpackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/dashboard");
            } else {
              let transfer = await mlmContract.methods.register(refAddress, amount, durationsdays)
                .send({ 
                  from: address,
                  value: amount
                });
                let addres = address.toLowerCase()
                let payload = {
                  user_address: addres,
                  amount: userenterBkcAmount,
                  distributeamount: userdistributeBkcamount,
                  referenceID: `${parentIdValue}`,
                  network: "VAL",
                  userID : useridvalue,
                  package: userbkcpackage,
                };
                const response = await axios.post(
                  `${config.BACKEND_URL}add-users`,
                  payload
                );
                toast.success("Register successfully");
                setLoader(false);
                navigate("/dashboard");
            }
            
          } catch (error) {
            setLoader(false);
          }
        }
        else {
          toast.error("Select package");
        }
      }
    }
  }
};
  
  const setpackageBkcAmount = async (network,joinamount, distamount, bkcpackage,duration) => {
    const match = duration.match(/^\d+/); 
    const durationpack = match ? match[0] : null;
    $(".dash-new-card").on("click", function () {
      $(".dash-new-card").removeClass("dashboard-active");
      $(this).addClass("dashboard-active");
    });
    setplantype(bkcpackage);
    setplandurations(durationpack);
    setplanamount(joinamount + " VBIT");
    setuserenterBkcAmount(joinamount);
    setuserdistributeBkcamount(distamount);
    setuserbkcpackage(bkcpackage);
  };


  const setpackagePolyAmount = async (network,joinamount, distamount, polypackage,duration) => {
    const match = duration.match(/^\d+/); 
    const durationpack = match ? match[0] : null;
    $(".dash-new-card").on("click", function () {
      $(".dash-new-card").removeClass("dashboard-active");
      $(this).addClass("dashboard-active");
    });
    setplantype(polypackage);
    setplandurations(durationpack);
    setplanamount(joinamount + network);
    setuserenterPolyAmount(joinamount);
    setuserdistributePolyamount(distamount);
    setuserpolypackage(polypackage);
  };

  const dashboardpage = async() => {
    let web3 = new Web3(config.rpcUrls);
    let sbtmlmContract = new web3.eth.Contract(
        sunflowerABI,
        config.BKC_MLMContract
    );
    if(localStorage.getItem("address")) { 
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      let userexists = await sbtmlmContract.methods.isUserExists(accounts[0]).call();
      if(userexists) {
        navigate('/dashboard')
      }
      else {
        toast.warn("Buy package");
      }
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;
      let userexists = await sbtmlmContract.methods.isUserExists(addr).call();
      if(userexists) {
        navigate('/dashboard')
      }
      else {
        toast.warn("Buy package");
      }
    }
  }

  return (
    <div className="App">

      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white">
              <AiOutlineAlignRight />
            </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
              <li class="nav-item">
                <a class="nav-link main-nav-connect-button" onClick={dashboardpage}>
                  Dashboard
                </a>
              </li>
              
              <li class="nav-item">
                <a class="nav-link main-nav-connect-button">{userAddress}</a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link main-nav-connect-button"
                  onClick={valobitlogout}
                >
                  <AiOutlinePoweroff
                    className="me-2"
                    style={{ fontSize: "18px" }}
                  />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section class="dashboard-values-design">
        <div class="container-fluid col-lg-11">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div className="register-card-design">
                <div className="d-flex flex-lg-row gap-3 mb-3 mt-5 justify-content-center">
                  {/* <div>
              <h2 className="fw-bold">Register</h2>
            </div> */}
                   <div className="text-center ref-input">
                    {userexiststatus == true ? (
                      ""
                    ) : (
                      <div className="d-flex flex-lg-row align-items-center mb-3 gap-3 justify-content-center dashboard-search-section">
                        <p className="mb-0">Referral ID</p>
                        <div
                          class="d-flex align-items-center overrall-search-id-section"
                          role="search"
                        >
                          {isEnabled ? (
                            <input
                            class="form-control"
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={handleChange}
                            value={message}
                          />
                          ) : (
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={handleChange}
                              value={message}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <br />
                    <div className="d-flex flex-lg-row align-items-center gap-3 justify-content-center dashboard-search-section">
                      <p className="mb-0">Plan Details</p>
                      <div
                        class="d-flex align-items-center overrall-search-id-section"
                        role="search"
                      >
                        <p className="mb-0">{planamount} </p>
                      </div>
                      {/* <p className="mb-0">Plan Details -</p>
                      <p className="mb-0">{plantype}</p>
                      <p className="mb-0">{planamount} VUSDT</p> */}
                    </div>

                    <p className="container-fluid d-flex col-lg-6 mb-1 mt-3">
                      {useridnotexist}
                    </p>
                  </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-3 mt-2 g-4 mb-5 col-lg-10 mx-auto">
                  {networkStatus === "VAL" ? (
                    <>
                      {Bkcdatatype.map((packageItem, index) => (
                        <div
                          className="col"
                          onClick={() =>
                            setpackageBkcAmount(
                              networkStatus,
                              packageItem.joiningAMT,
                              packageItem.distributionAMT,
                              packageItem.package,
                              packageItem.durations
                            )
                          }
                        >
                          <div class="card dash-new-card">
                            <div class="card-body py-lg-3 text-center">
                              <div className="">
                                <p className="dashboard-text-8">
                                  {packageItem.package}
                                </p>
                                <p className="dashboard-text-9">
                                  {packageItem.joiningAMT} VBIT
                                </p>
                                <p>{packageItem.durations} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : networkStatus === "SBT" ? (
                    <>
                      {Sbtdatatype.map((packageItem, index) => (
                        <div
                          className="col"
                          onClick={() =>
                            setpackagePolyAmount(
                              networkStatus,
                              packageItem.joiningAMT,
                              packageItem.distributionAMT,
                              packageItem.package,
                              packageItem.durations
                            )
                          }
                        >
                          <div class="card dash-new-card">
                            <div class="card-body py-lg-3 text-center">
                              <div className="">
                                <p className="dashboard-text-8">
                                  {packageItem.package}
                                </p>
                                <p className="dashboard-text-9">
                                  {packageItem.joiningAMT} SBT
                                </p>
                                <p>{packageItem.durations} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ):(
                    <>
                      {Polydatatype.map((packageItem, index) => (
                        <div
                          className="col"
                          onClick={() =>
                            setpackagePolyAmount(
                              networkStatus,
                              packageItem.joiningAMT,
                              packageItem.distributionAMT,
                              packageItem.package,
                              packageItem.durations
                            )
                          }
                        >
                          <div class="card dash-new-card">
                            <div class="card-body py-lg-3 text-center">
                              <div className="">
                                <p className="dashboard-text-8">
                                  {packageItem.package}
                                </p>
                                <p className="dashboard-text-9">
                                  {packageItem.joiningAMT} polyvbit
                                </p>
                                <p>{packageItem.durations} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {loader == true ? (
                  <button
                    class="nav-link main-nav-connect-button mx-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <button
                    class="nav-link main-nav-connect-button mx-auto"
                    style={{ width: "fit-content" }}
                    onClick={() => register()}
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
